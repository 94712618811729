import { RubricEditionDto } from "./RubricEdition.dto";

const rubricType = 1;

/**
 * @desc format de données envoyées pour créer une rubrique / reçues pour afficher une rubrique
 */
export class Rubric {

  constructor();
  constructor(data : any);
  constructor(data ? : any) {
    this.id = data && data.id || 0;
    this.name = data && data.name || "";
    this.type = data && data.type || rubricType;

    if(data && data.startDate) {
      if(typeof(data.startDate) === 'string')
        this.startDate = new Date(data.startDate);
      else this.startDate = data.startDate;
    }
    else this.startDate = new Date();

    if(data && data.endDate) {
      if(typeof(data.endDate) === 'string')
        this.endDate = new Date(data.endDate);
      else this.endDate = data.endDate;
    }
    else this.endDate = new Date();

    if(data && data.archivedDate) {
      if(typeof(data.archivedDate) === 'string')
        this.archivedDate = new Date(data.archivedDate);
      else this.archivedDate = data.archivedDate;
    }
    else this.archivedDate = new Date();
    
    if(data && data.image && data.image.id)
      this.imgId = data.image.id;
    else this.imgId = data && data.imgId || 0;

    this.publicationCount = data && data.publicationCount || 0;
  }

  id = 0;
  name ="";
  type = 1;
  startDate = new Date();
  endDate = new Date();
  archivedDate = new Date();
  imgId = 0;
  imgUrl? : string;
  imgChanged ? = false;

  publicationCount = 0;

  public clone(): any {
    const cloneObj = new Rubric() as any;
    for (const attribut in this) {
      cloneObj[attribut] = this[attribut];
    }
    return cloneObj;
  }

  public createRubricEditionDto(baseRubric : Rubric) : RubricEditionDto{
    const data : any = {};
    if(baseRubric.id !== this.id)  {
      console.log("error: wrong rubric id");
      return new RubricEditionDto();
    }

    data['id'] = this.id;

    if(baseRubric.name !== this.name)
      data['name'] = this.name;
    if(baseRubric.type !== this.type)
      data['type'] = this.type;
    if(baseRubric.startDate.toString() !== this.startDate.toString())
      data['startDate'] = this.startDate;
    if(baseRubric.endDate.toString() !== this.endDate.toString())
      data['endDate'] = this.endDate;
    if(baseRubric.archivedDate.toString() !== this.archivedDate.toString())
      data['archivedDate'] = this.archivedDate;
    if(baseRubric.imgId !== this.imgId)
      data['imgId'] = this.imgId;
    if(baseRubric.imgUrl !== this.imgUrl)
      data['imgUrl'] = this.imgUrl;
    if(baseRubric.imgChanged !== this.imgChanged)
      data['imgChanged'] = this.imgChanged;

    return new RubricEditionDto(data);
  }
}