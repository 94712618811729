
  import RubricSumCard from '@/components/RubricSumCard.component.vue';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Component, Vue } from 'vue-property-decorator';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import axios from 'axios';
  import { Rubric } from '@/interfaces/Rubric.interface';

  /**
   * @desc page d'affichage des projets d'école non-archivés
   */
  @Component({
    components: {
      'rubric-sum-card' : RubricSumCard
    },
  })
  export default class SchoolProjects extends Vue {
    private mobileClass= '';

    private rubrics : Array<Rubric> = [];
    private loading = false;

    mounted() : void {
      this.loadProjects();
      this.mobileClass = (this as any).$mq === 'mobile' || (this as any).$mq === 'tablet' ? 'mobile' : '';
    }

    /**
     * @desc charge les projets d'école non archivés pour les afficher
     */
    private loadProjects() : void {
      const headers = AuthenticationService.getRequestHeader();

      this.loading = true;
      axios
        .get(UrlConsts.getAllUnarchivedProjects, headers)
        .then((response) => {
          this.loading = false;
          const rubrics = response.data as Rubric[];
          this.rubrics = rubrics.map(x => new Rubric(x));
        })
        .catch(error => {console.log(error); this.loading = false;})
    }
  }
