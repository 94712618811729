
  import { AuthenticationService } from '@/services/authentication.service';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import CustomImage from './CustomImage.component.vue';
  import {Utils} from '@/scripts/Utils';
  import { Rubric } from '@/interfaces/Rubric.interface';

  /**
   * @desc carte de résumé d'une rubrique
   */
  @Component({
    components: {
      'c-img' : CustomImage,
    },
  })
  export default class RubricSumCard extends Vue {

    /**
     * @desc la rubrique à résumer
     */
    @Prop({default: () => {return new Rubric();}})
    rubric? : Rubric;

    /**
     * @desc transformation de la date indiquée en texte compréhensible
     */
    private getStrDate(data : Date) : string {
      let day = data.getDate().toString();
      if(day.length === 1) day = '0' + day;

      return day + '/' + (data.getMonth()+1) + "/" + data.getFullYear();
    }

    /**
     * @desc navigue vers la page rubrique associée
     */
    private navToRubricArticles() : void {
      if(!this.rubric) return;
      this.$router.push('/rubriques/articles/' + this.rubric.id);
    }

    /**
     * @desc renvoie le nom complet de la rubrique
     */
    private getRubricFullName(rubric : Rubric) : string {
      return Utils.getRubricFullName(rubric.startDate, rubric.endDate, rubric.name);
    }
  }
