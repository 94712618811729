
/**
 * @desc format de données envoyées pour modifier une rubrique
 */
export class RubricEditionDto {

  constructor();
  constructor(data : any);
  constructor(data ? : any) {
    this.id = data.id;

    if(data && data.name)
      this.name = data.name;
    if(data && data.type)
      this.type = data.type;
    if(data && data.startDate)
      this.startDate = data.startDate;
    if(data && data.endDate)
      this.endDate = data.endDate;
    if(data && data.archivedDate)
      this.archivedDate = data.archivedDate;
    if(data && data.imgId)
      this.imgId = data.imgId;
    if(data && data.imgUrl)
      this.imgUrl = data.imgUrl;
    if(data && data.imgChanged)
      this.imgChanged = data.imgChanged;
  }

  id = 0;
  name? : string;
  type?  : number;
  startDate? : Date;
  endDate? : Date;
  archivedDate? : Date;
  imgId? : number;
  imgUrl? : string;
  imgChanged? : boolean;


}